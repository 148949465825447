import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Box, useMediaQuery } from "@mui/material";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect } from "./connector";

// const POLLING_INTERVAL = 12000;
// export const walletconnect = new WalletConnectConnector({
//   rpc: {
//     56: "https://bsc-dataseed.binance.org/",
//     97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
//   },
//   supportedChainIds: [56, 97],
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   // // key: null,
//   // qrcodeModal: QRCodeModal,
//   pollingInterval: POLLING_INTERVAL,
//   // infuraId: "c2e6cb823be147da987a69d4ffdc2e37"
// });

export default function WalletConnect({ open, setopen }) {
  //   const classes = useStyles();
  //   console.log("open", open);
  const matches = useMediaQuery("(max-width:600px)");
  var c;
  matches && window.web3 ? (c = injected) : (c = walletconnect);
  let data = [
    {
      name: "Metamask",
      dis: "Connect to your Metamask Wallet",
      img: "./assets/metamask.png",
      fun: injected,
    },
    {
      name: "walletConnect",
      dis: "Scan withWalletConnect to connect",
      img: "./assets/walletconnec.svg",
      fun: c,
    },
    {
      name: "TokenPocket",
      dis: "Connect to your  Wallet",
      img: "./assets/tokenpo.png",
      fun: injected,
    },
    {
      name: "TrustWallet",
      dis: "Connect to your  Wallet",
      img: "./assets/trust.png",
      fun: injected,
    },
    {
      name: "SafePal Wallet",
      dis: "Connect to your  Wallet",
      img: "./assets/safepal.png",
      fun: injected,
    },
  ];
  const { activate } = useWeb3React();
  //   const { onClose, open } = props;

  const handleClose = () => {
    setopen(false);
  };
  const handleClickOpen = () => {
    setopen(true);
  };
  return (
    <div>
      <Dialog onClose={() => handleClose()} open={open}>
        <DialogContent
          style={{
            background: "#8F0A0A",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          {data.map((item, i) => (
            <Grid
              key={`${item}+${i}`}
              style={{
                width: "100%",
                height: "20%",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: 20,
              }}
            >
              <Box
                onClick={async () => {
                  activate(item.fun);
                  handleClose();
                }}
                // onClick={() => WalletConn(item)}
                display="flex"
                alignItems="center"
                // border="1px solid red"
                bgcolor="white"
                m={1}
                borderRadius={5}
                p={1}
              >
                {/* <br /> */}
                <Box width="200px">{item.name}</Box>
                <Box>
                  <img
                    src={item.img}
                    alt="Metamask"
                    style={{ width: 40, height: 40 }}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}
