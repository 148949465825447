import React from "react";
import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import {
  token1Address,
  token2Address,
  contractAddress,
} from "./utils/environment";
import token1Abi from "./utils/token1Abi.json";
import token2Abi from "./utils/token2Abi.json";
import contractAbi from "./utils/contractAbi.json";
import Web3 from "web3";
const web3 = new Web3(
  Web3.givenProvider
  // ? Web3.givenProvider
  // : "https://data-seed-prebsc-2-s3.binance.org:8545/"
);
function useContract(address, ABI) {
  const { active, error, library } = useWeb3React();
  return React.useMemo(() => {
    if (active && !error && library) {
      const signer = library.getSigner();
      return new Contract(address, ABI, signer);
    } else {
      return null;
    }
  }, [address, ABI, active, error, library]);
}

//--------------for Contract
// using WEB3(not signer)
export function useSwapContract() {
  const swapContract = new web3.eth.Contract(contractAbi, contractAddress);
  return swapContract;
}
//using WEBE-REACT(signer)
export function useSwapContract1() {
  return useContract(contractAddress, contractAbi);
}

//--------------for Token1
// using WEB3(not signer)
export function useToken1Contract() {
  const token1Contract = new web3.eth.Contract(token1Abi, token1Address);
  return token1Contract;
}
//using WEBE-REACT(signer)
export function useToken1Contract1() {
  return useContract(token1Address, token1Abi);
}

//--------------for Token2
// using WEB3(not signer)
export function useToken2Contract() {
  const token2Contract = new web3.eth.Contract(token2Abi, token2Address);
  return token2Contract;
}
//using WEBE-REACT(signer)
export function useToken2Contract1() {
  return useContract(token2Address, token2Abi);
}
