import "./App.css";
import UniSwap from "./components/uni-swap";
import WalletConnect from "./modal";
import Modal from "./logoutModal";
import { useEffect, useState } from "react";
import NetworkChange from "./models/switchnetwork";
import Web3 from "web3";
function App() {
  const [open, setopen] = useState(false);
  const [logoutopen, setlogoutopen] = useState(false);
  const [switchNetwork, setswitchNetwork] = useState(false);

  const web3 = new Web3(
    Web3.givenProvider
    //|| "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
  );

  useEffect(() => {
    let chain = async () => {
      const chainid = await web3.eth.getChainId();
      if (chainid !== 1) {
        setswitchNetwork(true);
      } else {
        setswitchNetwork(false);
      }
    };
    chain();
  }, []);

  return (
    <div>
      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      <WalletConnect open={open} setopen={setopen} />
      <UniSwap
        logoutopen={logoutopen}
        setlogoutopen={setlogoutopen}
        open={open}
        setopen={setopen}
      />
      <Modal logoutopen={logoutopen} setlogoutopen={setlogoutopen} />
    </div>
  );
}

export default App;
