import { InjectedConnector } from "@web3-react/injected-connector";
// import { BscConnector } from "@binance-chain/bsc-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 42],
});
export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    // "https://mainnet.infura.io/v3/5b93df3949ce4450b962ab372d314b63",
    3: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    // "https://ropsten.infura.io/v3/5b93df3949ce4450b962ab372d314b63",
    4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    // "https://rinkeby.infura.io/v3/5b93df3949ce4450b962ab372d314b63",
    42: "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    //  "https://kovan.infura.io/v3/5b93df3949ce4450b962ab372d314b63",
  },
  supportedChainIds: [1, 3, 4, 42],
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 12000,
  chainId: 1,
});

// export const bsc = new BscConnector({
//   supportedChainIds: [56, 97],
// });
