import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";
import { formatUnits, parseUnits } from "@ethersproject/units";
// add 10%
export function calculateGasMargin(value) {
  return +(
    (value * BigNumber.from(10000).add(BigNumber.from(1000))) /
    BigNumber.from(10000)
  ).toFixed(0);
  // return value;
}
//CalculatePayableGas
// export const gasEstimationPayable = async (active, fn, value, dat, id) => {
//   if (active) {
//     const estimateGas = await fn.mint(dat, id, MaxUint256).catch(() => {
//       return fn.mint(dat, id, { value: parseUnits(value.toString()) });
//     });
//     return calculateGasMargin(estimateGas);
//   }
// };

export const gasEstimationApprove = async (active, fn, cA, amount) => {
  if (active) {
    const ap_estimateGas = await fn.approve(cA, MaxUint256).catch(() => {
      return fn.approve(cA, parseUnits(amount));
    });
    return calculateGasMargin(ap_estimateGas);
  }
};

export const gasEstimationSwap = async (active, fn, CA1, CA2, amount) => {
  if (active) {
    const ap_estimateGas = await fn.swap(CA1, CA2, MaxUint256).catch(() => {
      return fn.swap(CA1, CA2, amount);
    });
    return calculateGasMargin(ap_estimateGas);
  }
};
