import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { useWeb3React } from "@web3-react/core";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ logoutopen, setlogoutopen }) {
  const { deactivate } = useWeb3React();
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setlogoutopen(true);
  };

  const handleClose = () => {
    setlogoutopen(false);
    // deactivate()
  };

  const handleLogout = () => {
    handleClose();
    deactivate();
    setlogoutopen(false);
  };

  return (
    <div>
      <Dialog
        open={logoutopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            background: "linear-gradient(90deg, #ffe601, #ff8b03)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            color: "white",
          }}
        >
          {" Please Confirm Your Action"}
        </DialogTitle>
        <DialogContent style={{ background: "#000000" }}>
          <DialogContentText
            style={{ color: "white" }}
            id="alert-dialog-slide-description"
          >
            Are you sure you want to Logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ background: "#000000" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleLogout}>Logout</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
