// main net
export const token1Address = "0xca7b3ba66556C4Da2E2A9AFeF9C64F909A59430a";
export const token2Address = "0x2f72aD04c549E1ab350e52E8437Ec795309218B3";
export const contractAddress = "0x0dB77cD145edAE685Ee39bE9A8994E3b269029b5";
// testnet
// export const token1Address = "0x944A0F9185cb1a2F5F677f324660C0B8b0E92725";
// export const token2Address = "0x0d6457DEcf2964F6d8E43628D851005C0174C95a";
// export const contractAddress = "0xA602EeD593C7A48CE8586c4B65F5102954A50AEe";
// https://rinkeby.etherscan.io/address/0x0d6457DEcf2964F6d8E43628D851005C0174C95a#code
// https://rinkeby.etherscan.io/address/0x944A0F9185cb1a2F5F677f324660C0B8b0E92725#code
// https://rinkeby.etherscan.io/address/0xA602EeD593C7A48CE8586c4B65F5102954A50AEe#code
