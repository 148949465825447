import {
  Button,
  Grid,
  useMediaQuery,
  Hidden,
  IconButton,
  CircularProgress,
  Slider,
  ListItem,
  ListItemText,
  List,
  SwipeableDrawer,
  Divider,
  Container,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box } from "@mui/system";
import MenuIcons from "@mui/icons-material/Menu";
import "./uni-swap.css";
import Stack from "@mui/material/Stack";
import { useWeb3React } from "@web3-react/core";

import { useEffect, useRef, useState } from "react";
import {
  useSwapContract1,
  useToken1Contract1,
  useToken2Contract1,
} from "../hooks";
import { toast } from "react-toastify";
import { parseUnits, formatUnits } from "@ethersproject/units";
import {
  contractAddress,
  token2Address,
  token1Address,
} from "../utils/environment";
import homeBg from "../images/bgover.svg";
import CachedIcon from "@mui/icons-material/Cached";
import { gasEstimationApprove, gasEstimationSwap } from "../utils";

const Swap = ({ open, setopen, setlogoutopen, logoutopen, symbol }) => {
  const [opens, setopens] = useState(false);
  const matches = useMediaQuery("(max-width:960px)");
  // const matches1 = useMediaQuery("(max-width:400px)");
  // const matches2 = useMediaQuery("(max-width:900px)");

  const { account, active, activate, deactivate } = useWeb3React();
  // let account = "0x2aE3916Bc56E7815f823B3e73088aDC7323d7068";
  const [positionType, setpositionType] = useState(true);
  const [grandValue, setGrandValue] = useState(0);
  const [onChangeValue, setOnChangeValue] = useState("");
  const [loading, setloading] = useState(false);
  const [errorShow, setErrorShow] = useState("");
  const [maxLoading, setMaxLoading] = useState(false);
  const [wolverinBalance, setWolverinBalance] = useState(0);
  const [AdamantiumBalance, setAdamantiumBalance] = useState(0);
  const [ContractValue, setContractValue] = useState(0);
  const [walletBalance, setwalletBalance] = useState(0);
  // NS -> not signer
  // S -> signer
  const token1S = useToken1Contract1();
  const token2S = useToken2Contract1();
  const useSwapContractS = useSwapContract1();

  const init = async () => {
    const token1Decimal = await token1S.decimals();
    const token1Balance = await token1S.balanceOf(account);
    setwalletBalance(formatUnits(token1Balance.toString(), token1Decimal));
    console.log(token1Balance.toString(), "token1Balance Init");
    console.log(token1Decimal, "token1Decimal Init");
  };
  const init2 = async () => {
    const token2Decimal = await token2S.decimals();
    const token2Balance = await token2S.balanceOf(account);
    console.log(token2Decimal.toString(), "token1Balance Init");
    console.log(token2Balance, "token1Decimal Init");
    setwalletBalance(formatUnits(token2Balance.toString(), token2Decimal));
  };
  useEffect(() => {
    if (account) {
      positionType ? init() : init2();
    }
  }, [account, positionType]);

  // let account = "0x3721206E0edDeefcbE4ebb8091195e9d5071f99b";
  // const gettingBalance = async () => {
  //   if (active) {
  //     setMaxLoading(true);
  //     if (positionType) {
  //       const token1Decimal = await token1S.decimals();
  //       const token1Balance = await token1S.balanceOf(account);
  //       const t1b = Math.round(formatUnits(token1Balance, token1Decimal));

  //       setMaxLoading(false);
  //       setOnChangeValue(t1b);
  //       // setGrandValue(onChangeValue);
  //     } else {
  //       const token2Decimal = await token2S.decimals();
  //       const token2Balance = await token2S.balanceOf(account);
  //       const t2b = Math.round(formatUnits(token2Balance, token2Decimal));
  //       setMaxLoading(false);
  //       setOnChangeValue(t2b);
  //       // setGrandValue(onChangeValue);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (active) {
  //     (async () => {
  //       const token1Decimal = await token1S.decimals();
  //       const token1Balance = await token1S.balanceOf(account);
  //     })();
  //   }
  // }, [account]);

  let val = onChangeValue && onChangeValue.replaceAll(",", "");
  useEffect(() => {
    (async function () {
      if (active) {
        try {
          if (positionType) {
            if (token1S && token2S) {
              const token1Decimal = await token1S.decimals();
              const token1Balance = await token1S.balanceOf(account);
              const t1b = Math.round(formatUnits(token1Balance, token1Decimal));
              setWolverinBalance(t1b);
              const token2Decimal = await token2S.decimals();
              const token2Balance = await token2S.balanceOf(account);
              const t2b = Math.round(formatUnits(token2Balance, token2Decimal));
              setAdamantiumBalance(t2b);

              if (val > t1b) {
                let id = toast.loading("Processing Transaction.");
                toast.update(id, {
                  render: "You don't have enough amount",
                  type: "error",
                  isLoading: false,
                  autoClose: true,
                });
                setOnChangeValue("");
              } else {
                setGrandValue(val);
              }
            }
          } else {
            if (token1S && token2S) {
              const token2Decimal = await token2S?.decimals();
              const token2Balance = await token2S?.balanceOf(account);
              const t2b = Math.round(formatUnits(token2Balance, token2Decimal));
              const token1Decimal = await token1S?.decimals();
              const token1Balance = await token1S?.balanceOf(account);
              const t1b = Math.round(formatUnits(token1Balance, token1Decimal));
              setWolverinBalance(t1b);
              if (val > t2b) {
                let id = toast.loading("Processing Transaction.");
                toast.update(id, {
                  render: "You don't have enough amount",
                  type: "error",
                  isLoading: false,
                  autoClose: true,
                });
                setOnChangeValue("");
              } else {
                setGrandValue(val);
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [onChangeValue, positionType, grandValue, account]);

  useEffect(() => {
    if (account === undefined) {
      setOnChangeValue("");
    }
  }, [active, account]);

  const handleClickOpen = () => {
    setopen(true);
  };

  const handleLogoutopen = () => {
    setlogoutopen(true);
  };
  const swapHandler = async () => {
    let id = toast.loading("Processing Transaction.");
    if (active && +grandValue > 0) {
      try {
        if (positionType) {
          if (+grandValue <= 0) {
            toast.update(id, {
              render: "Please Enter Wolverinu Valid Amount.",
              type: "error",
              isLoading: false,
              autoClose: true,
            });
          } else {
            setloading(true);
            const tokenoneDecimal = await token1S.decimals();
            const allowance = await token1S.allowance(account, contractAddress);
            const valueToApprove = parseUnits(
              grandValue.toString(),
              tokenoneDecimal
            );

            const approveamout = parseUnits(
              "1000000000000000000",
              tokenoneDecimal
            );

            if (+allowance < +grandValue) {
              const gassprice = await gasEstimationApprove(
                active,
                token1S.estimateGas,
                contractAddress,
                approveamout
              );

              const tx = await token1S.approve(contractAddress, approveamout, {
                gasLimit: gassprice,
              });
              await tx.wait();
            }
            const gasspriceswap = await gasEstimationSwap(
              active,
              useSwapContractS.estimateGas,
              token1Address,
              token2Address,
              valueToApprove
            );

            const swap = await useSwapContractS.swap(
              token1Address,
              token2Address,
              valueToApprove,
              {
                gasLimit: gasspriceswap,
              }
            );
            await swap.wait();
            toast.update(id, {
              render: "Transaction Successfully Completed.",
              type: "success",
              isLoading: false,
              autoClose: true,
            });
            setloading(false);
            window.location.reload();
          }
        } else {
          if (+grandValue <= 0) {
            toast.update(id, {
              render: "Please Enter Adamantium Valid Amount.",
              type: "error",
              isLoading: false,
              autoClose: true,
            });
          } else {
            setloading(true);
            const tokentwodecimal = await token2S.decimals();
            const allowance = await token2S.allowance(account, contractAddress);
            const valueToApprove = parseUnits(
              grandValue.toString(),
              tokentwodecimal
            );
            const approveamout = parseUnits(
              "1000000000000000000",
              tokentwodecimal
            );

            if (+allowance < +grandValue) {
              const gasspriceApprove = await gasEstimationApprove(
                active,
                token2S.estimateGas,
                contractAddress,
                approveamout
              );

              const tx2 = await token2S.approve(contractAddress, approveamout, {
                gasLimit: gasspriceApprove,
              });
              await tx2.wait();
            }
            const gasspriceswap2 = await gasEstimationSwap(
              active,
              useSwapContractS.estimateGas,
              token2Address,
              token1Address,
              valueToApprove
            );
            const swap = await useSwapContractS.swap(
              token2Address,
              token1Address,
              valueToApprove,
              {
                gasLimit: gasspriceswap2,
              }
            );
            await swap.wait();
            toast.update(id, {
              render: "Transaction Successfully Completed.",
              type: "success",
              isLoading: false,
              autoClose: true,
            });
            setloading(false);
            window.location.reload();
          }
        }
      } catch (error) {
        if (error?.data?.message) {
          toast.update(id, {
            render: error?.data?.message,
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        } else {
          toast.update(id, {
            render: error?.message,
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        }
        setloading(false);
      }
    } else {
      toast.update(id, {
        render: "Please Enter Valid Amount.",
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  const data = [
    {
      name: "Game",
      path: "Game",
    },
    {
      name: "Merch",
      path: "Merch",
    },
    {
      name: "NFTs",
      path: "NFTs",
    },
    {
      name: "Staking",
      path: "Staking",
    },
    {
      name: "Swap",
      path: "Swap",
    },
    {
      name: "WolverNews",
      path: "WolverNews",
    },
  ];
  // var numbers = /^\d{1,3}(,\d{3})*(\.\d+)?$/;

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  const MaxValueHandler = () => {
    if (+walletBalance > 0) {
      setOnChangeValue(
        (
          Math.round(+walletBalance.replaceAll(",", "") * 100) / 100
        ).toLocaleString()
      );
    }
  };

  return (
    <div bgcolor="#000000">
      {/* Header Portion Starts Here */}

      <Box
        // maxWidth=""
        sx={{ background: "#222222", margin: "auto", zIndex: "-1" }}
      >
        <Grid container alignItems="center">
          <Grid
            item
            xs={4}
            md={4}
            padding={{ xs: "10px 20px" }}
            // textAlign={{ xs: "center" }}
            // xs={6}
            // md={3}
          >
            <a href="https://www.wolverinu.com/" target="_blank">
              <img src="logo.svg" width="70px" height="auto" alt="" srcset="" />
            </a>
          </Grid>
          {/* <Hidden mdUp>
          {/* <Grid
            style={{ background: "black" }}
            padding={{ xs: "10px 0px" }}
            item
            xs={6}
          >
            <SwipeableTemporaryDrawer
              open={open}
              setopen={setopen}
              setlogoutopen={setlogoutopen}
              logoutopen={logoutopen}
            />
          </Grid> 
          {/* </Hidden> */}
          <Hidden mdDown>
            <Grid item xs={8} md={8}>
              <Stack
                width="100%"
                // direction="row"
                margin={{ xs: "auto", md: "0px" }}
                direction={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "right", md: "space-between" }}
                spacing={2}
              >
                <Box
                  my={2}
                  width="50%"
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  fontWeight="400"
                >
                  {data.map(({ name, path }, index) => (
                    <a
                      activeClass="activated"
                      to={path}
                      smooth={true}
                      key={index}
                      offset={-100}
                      style={{ cursor: "pointer" }}
                    >
                      <Box
                        color="#fff"
                        fontSize={{ xs: "10px", sm: "14px" }}
                        letterSpacing="0.2px"
                        sx={{
                          "&:hover": {
                            background: `-webkit-linear-gradient(280.1deg, #A23939 50.48%, #830606 49.50%)`,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          },
                        }}
                      >
                        {name}
                      </Box>
                    </a>
                  ))}
                </Box>
                <Box
                  textAlign={{ xs: "right" }}
                  width={{ xs: "100%", md: "20%" }}
                  pr={2}
                >
                  {!active ? (
                    <Button
                      sx={{
                        maxWidth: "100%",
                        minHeight: "45px",
                        //
                        background: "linear-gradient(90deg,#890808, #5A0606)",
                        boxShadow: "0px 0px 13px rgba(182, 0, 211, 0.7)",
                        borderRadius: "24px",
                        color: "#fff",
                        padding: matches ? "10px 10px" : "10px 10px",
                        fontSize: matches ? "12px" : "13px",
                        border: "none",

                        cursor: "pointer",
                      }}
                      onClick={handleClickOpen}
                    >
                      Connect to wallet
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        maxWidth: "100%",
                        background: "linear-gradient(90deg,#890808, #5A0606)",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        color: "#D50066",
                        minHeight: "34px",
                        borderRadius: "8px",
                      }}
                      onClick={handleLogoutopen}
                    >
                      {" "}
                      {account?.slice(0, 6) + "..." + account?.slice(-4)}
                    </Button>
                  )}
                </Box>
              </Stack>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={8} md={8} my={2} textAlign="right">
              <Box width="80%">
                <IconButton
                  style={{
                    color: "#fff",
                    background: `-webkit-linear-gradient(315.1deg, #A23939 50.48%, #830606 49.50%)`,
                    borderRadius: "4px",
                  }}
                  edge="start"
                  aria-label="open drawer"
                  onClick={() => {
                    setopens(true);
                  }}
                >
                  <MenuIcons />
                </IconButton>
                <SwipeableDrawer
                  // style={{ backgroundColor: "red" }}
                  anchor="left"
                  open={opens}
                  onClose={() => {
                    setopens(false);
                  }}
                  onOpen={() => {
                    setopens(true);
                  }}
                >
                  <List>
                    <ListItem
                      sx={{
                        "&:hover": {
                          color: "red",
                        },
                      }}
                      button
                      onClick={() => {
                        setopens(false);
                      }}
                    >
                      <ListItemText primary={"GAME"} />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      sx={{
                        "&:hover": {
                          color: "red",
                        },
                      }}
                      button
                      onClick={() => {
                        setopens(false);
                      }}
                    >
                      <ListItemText primary={"MERCH"} />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      sx={{
                        "&:hover": {
                          color: "red",
                        },
                      }}
                      button
                      onClick={() => {
                        setopens(false);
                      }}
                    >
                      <ListItemText primary={"NFTS"} />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      sx={{
                        "&:hover": {
                          color: "red",
                        },
                      }}
                      button
                      onClick={() => {
                        setopens(false);
                      }}
                    >
                      <ListItemText primary={"STAKING"} />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      sx={{
                        "&:hover": {
                          color: "red",
                        },
                      }}
                      button
                      onClick={() => {
                        setopens(false);
                      }}
                    >
                      <ListItemText primary={"SWAP"} />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      sx={{
                        "&:hover": {
                          color: "red",
                        },
                      }}
                      button
                      onClick={() => {
                        setopens(false);
                      }}
                    >
                      <ListItemText primary={"WOLVERNEWS"} />
                    </ListItem>
                  </List>
                  <Divider />
                </SwipeableDrawer>
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Box>

      <Box
        py={16}
        px={16}
        bgcolor="black"
        style={{
          background: `url(${homeBg}),linear-gradient(72deg, rgb(0, 0, 0), rgb(144, 10, 10) 51%, rgb(112, 0, 0) 95%`,
          clipPath: "polygon(0 0, 100% 0%, 100% 85%, 0% 100%)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
        }}
        fontSize={{ xs: "28px", md: "42px" }}
        fontWeight="500"
        color="#fff"
        zIndex="10"
      >
        Swap
      </Box>

      {/* Swap Portion Starts Here */}

      <Box
        maxWidth="xl"
        sx={{
          // margin: "100px auto",
          zIndex: "9999",
          border: "none",
          margin: "auto",
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12} height="80vh" mt={5}>
            <Box
              width={{ xs: "95%", md: "55%" }}
              sx={{
                // background:
                //   "linear-gradient(270.54deg,rgb(16, 11, 18) -2.11%, rgb(24, 20, 26) 103.66%)",
                // boxShadow: "0px 0px 13px rgba(182, 0, 211, 0.7)",
                margin: "auto",
                alignSelf: "center",
                // borderRadius: "30px",
                padding: "8px",
              }}
            >
              <Grid container mt={2} py={4}>
                {/* <Grid
                  item
                  xs={12}
                  style={{
                    color: "white",
                    padding: "0px 8px",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "30px",
                  }}
                >Swap
                </Grid> */}
                <Grid item xs={12} mt={3}>
                  <Box
                    style={{
                      border: "1px solid red",
                      // borderRadius: "20px",
                      minHeight: "30px",
                      padding: "16px 10px 22px 10px",
                      backgroundColor: "black",
                    }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item xs={matches ? 12 : 6}>
                        <Box
                          color="#fff"
                          fontSize={{ xs: "24px", md: "28px" }}
                          fontWeight="500"
                          display="flex"
                          alignItems="center"
                        >
                          <input
                            type="text"
                            value={onChangeValue == "NaN" ? "0" : onChangeValue}
                            id="ethinput"
                            onChange={(e) => {
                              setContractValue(+e.target.value);
                              if (e.target.value == "NaN") {
                                toast.error("Please enter a valid value");
                              } else {
                                setOnChangeValue(
                                  (
                                    Math.round(
                                      +e.target.value.replaceAll(",", "") * 100
                                    ) / 100
                                  ).toLocaleString()
                                );
                              }

                              if (+e.target.value >= 0) {
                                if (positionType) {
                                  setGrandValue(+e.target.value);
                                } else {
                                  setGrandValue(+e.target.value);
                                }
                              }
                            }}
                            placeholder="0"
                            style={{
                              width: "100%",
                              height: "10vh",
                              background: "transparent",
                              color: "white",
                              fontSize: "2rem",
                              border: "none",
                            }}
                          />
                        </Box>
                      </Grid>

                      {/* <Grid
                        item
                        xs={matches ? 5 : 8.5}
                        // sm={9}
                        // sx={{ paddingLeft: "2rem" }}
                      >
                        <input
                          value={onChangeValue}
                          id="ethinput"
                          onChange={(e) => {
                            setOnChangeValue(e.target.value);
                            if (+e.target.value >= 0) {
                              if (positionType) {
                                setGrandValue(+e.target.value);
                              } else {
                                setGrandValue(+e.target.value);
                              }
                            }
                          }}
                          type="number"
                          placeholder="0"
                          style={{
                            textAlign: "right",
                            border: "0px",
                            color: "yellow",
                            background: "black",
                            minHeight: "33px",
                            width: "100%",
                            fontSize: matches ? "12px" : "16px",
                            // paddingRight: "4rem",
                          }}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6} ml="auto">
                        {/* <Box ml="auto"> */}

                        {positionType ? (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="right"
                          >
                            <Box width="30px">
                              <img src="logo.svg" width="100%" />
                            </Box>
                            <Box color="#fff" fontWeight="700">
                              Wolverinu
                            </Box>
                          </Stack>
                        ) : (
                          <Stack
                            // ml="auto"
                            direction="row"
                            spacing={2}
                            justifyContent="right"
                          >
                            <Box width="30px">
                              <img src="logo.svg" width="100%" />
                            </Box>
                            <Box color="#fff" fontWeight="700">
                              Adamantium
                            </Box>
                          </Stack>
                        )}

                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          justifyContent="right"
                        >
                          <Box color="red">
                            Balance:
                            {positionType
                              ? wolverinBalance.toLocaleString()
                              : AdamantiumBalance.toLocaleString()}
                          </Box>
                          <Box>
                            {maxLoading ? (
                              <Button
                                fontSize={{ xs: "10px", sm: "17px" }}
                                variant="outlined"
                                sx={{
                                  textAlign: "right",
                                  background: "grey",
                                  // borderRadius: "14px",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                MAX
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={MaxValueHandler}
                                sx={{
                                  textAlign: "right",
                                  background: "#8F0A0A",
                                  // borderRadius: "14px",
                                  color: "white",

                                  fontWeight: "bold",
                                }}
                              >
                                MAX
                              </Button>
                            )}
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ position: "relative" }}>
                  <Box style={{ textAlign: "center" }} mt={-2} mb={-2}>
                    <IconButton
                      sx={{
                        borderRadius: "10px",
                        width: "40px",
                        height: "40px",
                        // padding: "5px",
                      }}
                      onClick={() => setpositionType(!positionType)}
                    >
                      <Box
                        bgcolor="#F8F9FA"
                        borderRadius="10px"
                        border="3px solid white"
                        width="25px"
                        height="25px"
                        padding="5px"
                      >
                        <CachedIcon
                          sx={{
                            color: "red",
                          }}
                        />
                      </Box>
                    </IconButton>

                    {/* <Button
                      style={{
                        background: "#A51212",
                        color: "white",
                        borderRadius: "16px",
                        marginTop: "-30px",
                      }}
                      onClick={() => {
                        setswitchbtn((prevCheck) => !prevCheck);
                      }}
                    >
                      Switch Action
                    </Button> */}
                  </Box>

                  <Box
                    style={{
                      border: "1px solid red",
                      // borderRadius: "20px",
                      minHeight: "30px",
                      padding: "16px 12px 24px 12px",
                      background: "black",
                    }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item xs={matches ? 12 : 6}>
                        <Box
                          color="#fff"
                          fontSize={{ xs: "24px", sm: "28px" }}
                          fontWeight="500"
                        >
                          <input
                            readOnly
                            id="tkninput"
                            value={onChangeValue == "NaN" ? "0" : onChangeValue}
                            onChange={(e) => {
                              if (e.target.value == "NaN") {
                                toast.error("Please enter a valid value");
                              } else {
                                setOnChangeValue(
                                  (
                                    Math.round(
                                      +e.target.value.replaceAll(",", "") * 100
                                    ) / 100
                                  ).toLocaleString()
                                );
                              }
                              if (+e.target.value >= 0) {
                                if (positionType) {
                                  setGrandValue(+e.target.value);
                                } else {
                                  setGrandValue(+e.target.value);
                                }
                              }
                            }}
                            type="text"
                            placeholder="0"
                            style={{
                              width: "100%",
                              height: "10vh",
                              background: "transparent",
                              color: "white",
                              fontSize: "2rem",

                              border: "0px",
                              minHeight: "33px",

                              // fontSize: matches ? "12px" : "16px",
                            }}
                          />
                          {/* 234,534,567.8890 */}
                        </Box>
                        {/* {!positionType ? (
                          <Button
                            variant="outlined"
                            style={{
                              background: "#8F0A0A",
                              borderRadius: "18px",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            <img
                              style={{
                                width: "24px",
                                height: "24px",
                                marginRight: "5px",
                              }}
                              src={buttonIcon}
                            />
                            Wolverinu
                            <i
                              style={{ marginLeft: "5px" }}
                              class="fas fa-chevron-down"
                            ></i>
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            style={{
                              background: "#8F0A0A",
                              borderRadius: "14px",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            ADAMANTIUM
                            {symbol}
                            <i
                              style={{ marginLeft: "5px" }}
                              class="fas fa-chevron-down"
                            ></i>
                          </Button>
                        )} */}
                      </Grid>

                      <Grid
                        item
                        xs={matches ? 12 : 3}
                        justifyContent="space-between"
                      >
                        {!positionType ? (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="right"
                          >
                            <Box width="30px">
                              <img src="logo.svg" width="100%" />
                            </Box>
                            <Box color="#fff" fontWeight="700">
                              Wolverinu
                            </Box>
                          </Stack>
                        ) : (
                          <Stack
                            ml="auto"
                            direction="row"
                            spacing={2}
                            justifyContent="right"
                          >
                            <Box width="30px">
                              <img src="logo.svg" width="100%" />
                            </Box>
                            <Box color="#fff" fontWeight="700">
                              Adamantium
                            </Box>
                          </Stack>
                        )}
                        <Box color="red" textAlign="right">
                          <Box color="red">
                            Balance:{" "}
                            {!positionType
                              ? wolverinBalance.toLocaleString()
                              : AdamantiumBalance.toLocaleString()}
                          </Box>
                        </Box>
                        {/* <input
                          readOnly
                          id="tkninput"
                          value={onChangeValue}
                          onChange={(e) => {
                            setOnChangeValue(e.target.value);
                            if (+e.target.value >= 0) {
                              if (positionType) {
                                setGrandValue(+e.target.value);
                              } else {
                                setGrandValue(+e.target.value);
                              }
                            }
                          }}
                          type="number"
                          placeholder="0"
                          style={{
                            textAlign: "right",

                            border: "0px",
                            color: "yellow",
                            background: "black",
                            minHeight: "33px",
                            width: "100%",
                            fontSize: matches ? "12px" : "16px",
                          }}
                        /> */}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} mt={3} textAlign="center">
                  {
                    !active ? (
                      <Button
                        style={{
                          background: "#8F0A0A",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          border: "none",
                          color: "#fff",
                          padding: "10px 40px",
                          fontSize: matches ? "15px" : "20px",
                          // borderRadius: "18px",
                          width: "90%",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                        onClick={handleClickOpen}
                      >
                        Connect Wallet
                      </Button>
                    ) : loading ? (
                      <Button
                        style={{
                          background:
                            "linear-gradient(90deg, #ffe601, #ff8b03)",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          border: "none",
                          color: "#fff",
                          padding: "10px 40px",
                          fontSize: matches ? "15px" : "20px",
                          // borderRadius: "18px",
                          width: "90%",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                        Loading <CircularProgress color="inherit" />
                      </Button>
                    ) : (
                      <Button
                        style={{
                          background: "#AB020E",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          color: "white",
                          // borderRadius: "20px",
                          padding: "16px",
                          fontWeight: "500",
                          fontSize: "16px",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                        onClick={swapHandler}
                      >
                        Swap
                      </Button>
                    )

                    // <Button style={{background:"#A51212",color:"white",borderRadius: '20px',padding:"16px",fontWeight:"500",fontSize:"16px",width:"100%"}} onClick={()=>{
                    //     setbuyrequest(true);
                    // }}>Proceed</Button>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor="#000000" py={8} borderTop="1px solid grey">
        <Container maxWidth="lg">
          <Box
            fontSize={{ xs: "28px", md: "42px" }}
            color="#fff"
            textAlign="center"
          >
            Visit us on
          </Box>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-around"
            width="100%"
            py={3}
            my={1}
            borderBottom="1px solid grey"
          >
            <Box width={{ xs: "80%", md: "60%" }} mx="auto">
              <Box color="red" fontSize={{ xs: "15px", md: "24px" }}>
                WOLVERINU,LLC
              </Box>
              <Stack direction="row" spacing={2} my={2}>
                <Stack direction="column" color="red">
                  <Box>Company Number</Box>
                  <Box>Head Office adress</Box>
                </Stack>
                <Stack direction="column" color="#fff">
                  <Box>L21000484911</Box>
                  <Box>Head Office adress</Box>
                </Stack>
              </Stack>
            </Box>
            <Box width={{ xs: "80%", md: "40%" }} mx="auto">
              <Stack
                direction="row"
                fontSize={{ xs: "20px", md: "32px" }}
                justifyContent="space-between"
                my={2}
                color="red"
              >
                <Box>
                  <TelegramIcon />
                </Box>
                <Box>
                  <RedditIcon />
                </Box>
                <Box>
                  <i class="fab fa-discord"></i>
                </Box>
                <Box>
                  <TwitterIcon />
                </Box>
                <Box>
                  <InstagramIcon />
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                color="#fff"
              >
                <Box color="red">Contract</Box>
                <br />
                <Box>
                  {contractAddress.slice(0, 5) +
                    "...." +
                    contractAddress.slice(-5)}
                </Box>
                <Box>
                  <CopyToClipboard
                    text={contractAddress}
                    onCopy={() => toast("copied!")}
                  >
                    <FileCopyOutlinedIcon />
                    {/* toast("Copied!") */}
                  </CopyToClipboard>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Box color="#fff" my={4} fontSize={{ xs: "12px", md: "16px" }}>
            This site contains material that may be copyrighted with use of
            which has not always been specifically authorized by the copyright
            owner. All materials contained in this site are presented as a
            transformative works in an act of parody, and the Wolverinu artist
            original interpretations. Wolverinu does not claim any partnership,
            endorsement, co-ownership, or sponsorship with any other entity. The
            existence and actions of Wolverinu do not adversely impact the
            market for any property owners. Furthermore, Wolverinu makes all
            content within available for the purpose of education in the ever
            growing Blockchain environment. These actions constitute for a “fair
            use” of any such material contained within under Title 17, Section
            107 of the U.S. Copyright Law.
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            mx="auto"
            width="100%"
            textAlign="center"
          >
            <Box color="#fff">Copyright 2021</Box>
            <img
              src="logo.svg"
              style={{
                width: "5%",
                paddingLeft: "10px",
              }}
            />
            <Box
              color="red"
              fontWeight="700"
              fontSize={{ xs: "18px", md: "24px" }}
            >
              Wolverinu
            </Box>
          </Stack>
        </Container>
      </Box>
      {/* <Box textAlign="center" pb={5} mt={4} bgcolor="#000000  ">
        <img src={footerlogo} alt="" style={{ width: "50%", height: "100%" }} />
      </Box> */}
    </div>
  );
};

export default Swap;

// const token1Decimal = await token1S.decimals();
// const token1Balance = await token1S.balanceOf(account);
// console.log(
//   "token1Balance",
//   Math.round(formatUnits(token1Balance, token1Decimal))
// );
// const t1b = Math.round(formatUnits(token1Balance, token1Decimal));
// if (onChangeValue > t1b) {
//   let id = toast.loading("Processing Transaction.");
//   toast.update(id, {
//     render: "You don't have enough amount",
//     type: "error",
//     isLoading: false,
//     autoClose: true,
//   });
//   setOnChangeValue("");
// }

// if (positionType) {
//   const token1Decimal = await token1S.decimals();
//   const token1Balance = await token1S.balanceOf(account);
//   let valueAfterPercentageCalculationOfToken1 =
//     (await (onChangeValue / 100)) *
//     formatUnits(token1Balance, token1Decimal);
//   if (valueAfterPercentageCalculationOfToken1 > 0) {
//     setGrandValue(Math.round(valueAfterPercentageCalculationOfToken1));
//   } else {
//     setGrandValue(0);
//   }
//   console.log("grandValue1:", grandValue);
// } else {
//   const token2Decimal = await token2S.decimals();
//   const token2Balance = await token2S.balanceOf(account);
//   let valueAfterPercentageCalculationOfToken2 =
//     (await (onChangeValue / 100)) *
//     formatUnits(token2Balance, token2Decimal);
//   if (valueAfterPercentageCalculationOfToken2 > 0) {
//     setGrandValue(Math.round(valueAfterPercentageCalculationOfToken2));
//   } else {
//     setGrandValue(0);
//   }
//   console.log("grandValue2:", grandValue);
// }
